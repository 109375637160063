<template>
  <v-card class="mx-auto" :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" xs="12" md="8">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                small
                plain
                icon
                :to="{
                  name: 'facturacion_servicios',
                }"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </template>
            <span v-text="'Átras'" />
          </v-tooltip>
          <span class="text-h6 ml-1" v-text="$route.meta.title" />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
          <v-tooltip v-if="data" bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                dark
                x-small
                class="ml-1"
                color="blue-grey darken-3"
                @click="report12Dialog"
              >
                <v-icon> mdi-update </v-icon>
              </v-btn>
            </template>
            <span v-text="'Fechas línea 12'" />
          </v-tooltip>
          <v-tooltip
            v-if="
              data &&
              data.reception_date &&
              !data.stamp_id &&
              !data.consignment_verify
            "
            bottom
          >
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                dark
                x-small
                class="ml-1"
                color="cyan darken-3"
                @click="payDayDialog"
              >
                <v-icon> mdi-calendar-refresh </v-icon>
              </v-btn>
            </template>
            <span v-text="'Día de recepción y pago'" />
          </v-tooltip>
          <v-tooltip
            v-if="
              (data && login.role_id == 1) ||
              (login.role_id != 1 && !data.stamp_id && !data.consignment_verify)
            "
            bottom
          >
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                dark
                x-small
                class="ml-1"
                color="teal darken-3"
                @click="amountsDialog"
              >
                <v-icon> mdi-currency-usd </v-icon>
              </v-btn>
            </template>
            <span v-text="'Montos'" />
          </v-tooltip>
          <v-tooltip v-if="login.role_id == 1 && data" bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                dark
                x-small
                class="ml-1"
                :color="data.taxed_amount == null ? '' : 'light-green darken-3'"
                @click="taxedDlg"
              >
                <v-icon> mdi-file-table </v-icon>
              </v-btn>
            </template>
            <span v-text="'Gravado y Exento'" />
          </v-tooltip>
          <v-tooltip v-if="data" bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                dark
                x-small
                class="ml-1"
                color="error"
                @click="observationDialog"
              >
                <v-icon> mdi-folder-alert </v-icon>
              </v-btn>
            </template>
            <span v-text="'Observación'" />
          </v-tooltip>
          <v-tooltip v-if="data" bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                dark
                x-small
                class="ml-1"
                color="info"
                @click="observationAdminDialog"
              >
                <v-icon> mdi-message-draw </v-icon>
              </v-btn>
            </template>
            <span v-text="'Observación administrativa'" />
          </v-tooltip>
          <v-tooltip
            bottom
            v-if="data && data.consignment && !data.consignment_verify"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                dark
                x-small
                class="ml-1"
                color="warning"
                @click="consigmentVerify"
              >
                <v-icon> mdi-file-check </v-icon>
              </v-btn>
            </template>
            <span v-text="'Validar'" />
          </v-tooltip>
          <v-tooltip
            v-if="
              data &&
              !data.insurance_reception_date &&
              (data.stamp_id != null || data.consignment_verify)
            "
            bottom
          >
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                dark
                x-small
                class="ml-1"
                color="grey"
                @click="insuranceReceptionDialog"
              >
                <v-icon> mdi-folder-star-multiple </v-icon>
              </v-btn>
            </template>
            <span v-text="'Guardar documentación aseguradora'" />
          </v-tooltip>
          <v-tooltip v-if="data && data.insurance_reception_date" bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                dark
                x-small
                class="ml-1"
                color="pink darken-2"
                @click="insuranceDlg"
              >
                <v-icon> mdi-folder-star-multiple </v-icon>
              </v-btn>
            </template>
            <span v-text="'Documentación aseguradora'" />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text v-if="data">
      <v-row>
        <v-col cols="12">
          <v-alert :type="data.reception_date ? 'success' : 'warning'" dense>
            <div
              class="text-center"
              v-text="
                data.reception_date
                  ? `F. recepción ${data.reception_date} | F. pago ${data.pay_date}`
                  : 'Fechas de recepción y pago no han sido calculadas'
              "
            />
          </v-alert>
          <v-alert v-if="data.observation" type="error" dense>
            <div class="text-center" v-text="data.observation" />
          </v-alert>
          <v-alert v-if="data.observation_admin" type="info" dense>
            <div class="text-center" v-text="data.observation_admin" />
          </v-alert>
        </v-col>
        <v-col cols="12">
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span
                  class="text-caption"
                  v-text="`SERVICIO | ${data.folio_full}`"
                />
              </v-toolbar-title>
            </v-toolbar>
            <v-spacer />
            <v-card-text>
              <v-row dense>
                <v-col cols="12" sm="12" md="3">
                  <ViewData
                    label="Fecha"
                    :value="data.rs_service.letter_generated"
                  />
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <ViewData
                    label="Aseguradora"
                    :value="data.rs_service.rs.insurance.name"
                  />
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <ViewData
                    label="Monto asegurado"
                    :value="numberFormat(data.rs_service.assured_amount)"
                  />
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <ViewData
                    label="ID SM"
                    :value="data.rs_service.rs.enrollment"
                  />
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <ViewData
                    label="Asegurado"
                    :value="
                      data.rs_service.rs.name +
                      ' ' +
                      data.rs_service.rs.first_surname +
                      ' ' +
                      data.rs_service.rs.second_surname
                    "
                  />
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <ViewData
                    label="Contratante"
                    :value="data.rs_service.rs.insured.contractor.name"
                  />
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <ViewData
                    label="Oficina"
                    :value="data.rs_service.rs.insured.office_address"
                  />
                </v-col>
                <v-col
                  v-if="data.rs_service.rs_service_type_id == 1"
                  cols="12"
                  sm="12"
                  md="3"
                >
                  <ViewData
                    label="Médico"
                    :value="
                      'IDM ' +
                      data.rs_service.doctor_id +
                      ' | ' +
                      data.rs_service.doctor.name +
                      ' ' +
                      data.rs_service.doctor.first_surname +
                      ' ' +
                      data.rs_service.doctor.second_surname
                    "
                  />
                </v-col>
                <v-col
                  v-if="data.rs_service_insured_bill"
                  cols="12"
                  xs="12"
                  md="3"
                >
                  <ViewData
                    label="Especialidad"
                    :value="
                      data.rs_service_insured_bill.rs_service.specialty_type
                        .specialty_type
                    "
                  />
                </v-col>
                <v-col
                  v-if="data.rs_service_insured_bill"
                  cols="12"
                  xs="12"
                  md="3"
                >
                  <ViewData
                    label="Consult. | Hosp."
                    :value="data.rs_service_insured_bill.provider_trade_name"
                  />
                </v-col>
                <v-col
                  v-if="data.rs_service.rs_service_type_id == 2"
                  cols="12"
                  sm="12"
                  md="3"
                >
                  <ViewData
                    label="Proveedor"
                    :value="data.rs_service.provider.trade_name"
                  />
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <span
                    class="text-caption font-weight-bold"
                    v-text="'Carta autorización'"
                  />
                  <br />
                  <span
                    class="text-description"
                    v-text="data.rs_service.letter_generated"
                  />
                  <v-tooltip bottom v-if="data.rs_service.letter_generated">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        small
                        v-on="on"
                        :href="
                          data.rs_service.rs_service_type_id != 3
                            ? url_pdf + '/carta/servicios/' + data.rs_service.id
                            : url_pdf +
                              '/rs/refound/bills/' +
                              data.rs_service.rs_id
                        "
                        target="_blank"
                      >
                        <v-icon small>mdi-eye</v-icon>
                      </v-btn>
                    </template>
                    <span v-text="'Ver'" />
                  </v-tooltip>
                </v-col>
                <v-col
                  v-if="data.rs_service.admission_date_report"
                  cols="12"
                  sm="12"
                  md="3"
                >
                  <ViewData
                    label="F. ingreso línea 12"
                    :value="data.rs_service.admission_date_report"
                  />
                </v-col>
                <v-col
                  v-if="data.rs_service.discharged_report"
                  cols="12"
                  sm="12"
                  md="3"
                >
                  <ViewData
                    label="F. alta línea 12"
                    :value="data.rs_service.discharged_report"
                  />
                </v-col>
                <v-col v-if="data.user_validate" cols="12" xs="12" md="3">
                  <ViewData
                    label="Verificado por"
                    :value="data.user_validate.email"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col v-if="data && rha" cols="12">
          <RhaWithRsService :rha="rha" />
        </v-col>
        <v-col v-if="data.rs_service.rs.rs_documents.length > 0" cols="12">
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span class="text-caption" v-text="'DOCUMENTOS'" />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items> </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
              <v-row dense>
                <v-col cols="12">
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th width="20px" v-text="'#'" />
                          <th v-text="'Descripción'" />
                          <th width="50px" v-text="'Visible'" />
                          <th width="50px" />
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, i) in data.rs_service.rs.rs_documents"
                          :key="i"
                        >
                          <td class="font-weight-bold" v-text="i + 1" />
                          <td v-text="item.description" />
                          <td>
                            <v-checkbox
                              v-model="item.external_display"
                              @change="docExtDiplay(item, 'rs_documents')"
                            />
                          </td>
                          <td>
                            <v-tooltip left>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  x-small
                                  fab
                                  outlined
                                  color="warning"
                                  :href="
                                    url_documents + '/rs_documents/' + item.url
                                  "
                                  target="_blank"
                                >
                                  <v-icon> mdi-download </v-icon>
                                </v-btn>
                              </template>
                              <span v-text="'Descargar'" />
                            </v-tooltip>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col v-if="data.rs_service_bill_files.length > 0" cols="12">
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span class="text-caption" v-text="'ARCHIVOS DIGITALES'" />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items> </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
              <v-row dense>
                <v-col cols="12">
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th width="20px" v-text="'#'" />
                          <th v-text="'Descripción'" />
                          <th width="50px" v-text="'Visible'" />
                          <th width="50px" />
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, i) in data.rs_service_bill_files"
                          :key="i"
                        >
                          <td class="font-weight-bold" v-text="i + 1" />
                          <td v-text="item.description" />
                          <td>
                            <v-checkbox
                              v-model="item.external_display"
                              @change="
                                docExtDiplay(item, 'rs_service_bill_files')
                              "
                            />
                          </td>
                          <td>
                            <v-tooltip left>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  x-small
                                  fab
                                  outlined
                                  color="warning"
                                  :href="
                                    url_documents +
                                    '/rs_service_bills/' +
                                    item.url
                                  "
                                  target="_blank"
                                >
                                  <v-icon> mdi-download </v-icon>
                                </v-btn>
                              </template>
                              <span v-text="'Descargar'" />
                            </v-tooltip>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col v-if="data.rs_service_insured_bill" cols="12">
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span
                  class="text-caption"
                  v-text="
                    `COBRO  | ${data.rs_service_insured_bill.folio} | ${
                      !data.rs_service_insured_bill.stamp_id ? 'SIN' : 'CON'
                    } FACTURA`
                  "
                />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items>
                <v-tooltip left v-if="data.rs_service_insured_bill.stamp_id">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      color="cyan darken-3"
                      class="ml-1"
                      icon
                      @click.prevent="
                        downloadFile(
                          data.rs_service_insured_bill.stamp_id,
                          'pdf'
                        )
                      "
                    >
                      <v-icon> mdi-file-download </v-icon>
                    </v-btn>
                  </template>
                  <span v-text="'Descargar PDF'" />
                </v-tooltip>
                <v-tooltip left v-if="data.rs_service_insured_bill.stamp_id">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      color="teal darken-2"
                      class="ml-1"
                      icon
                      @click.prevent="
                        downloadFile(
                          data.rs_service_insured_bill.stamp_id,
                          'xml'
                        )
                      "
                    >
                      <v-icon> mdi-xml </v-icon>
                    </v-btn>
                  </template>
                  <span v-text="'Descargar XML'" />
                </v-tooltip>
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      color="orange darken-3"
                      class="ml-1"
                      icon
                      :disabled="!data.rs_service_insured_bill.url_ticket"
                      :href="
                        url_documents +
                        '/rs_service_insured_bills/' +
                        data.rs_service_insured_bill.url_ticket
                      "
                      target="_blank"
                    >
                      <v-icon> mdi-receipt-text </v-icon>
                    </v-btn>
                  </template>
                  <span v-text="'Recibo de pago'" />
                </v-tooltip>
              </v-toolbar-items>
            </v-toolbar>
            <v-spacer />
            <v-card-text>
              <v-row dense>
                <v-col cols="12">
                  <v-alert
                    v-if="!data.rs_service_insured_bill.valid"
                    type="warning"
                    color="purple darken-3"
                    dark
                    dense
                  >
                    <div
                      class="text-center"
                      v-text="'Cobro ID no conciliado'"
                    />
                  </v-alert>
                </v-col>
                <v-col cols="12" xs="12" md="6">
                  <ViewData
                    label="Paciente | C. electrónico"
                    :value="data.rs_service_insured_bill.rs_service.rs.email"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Monto"
                    :value="
                      numberFormat(data.rs_service_insured_bill.pay_amount)
                    "
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Total cobrado"
                    :value="
                      numberFormat(
                        data.rs_service_insured_bill.insurance_pay_amount
                      )
                    "
                  />
                </v-col>
              </v-row>
              <v-row v-if="data.rs_service_insured_bill.stamp_id" dense>
                <v-col cols="12" xs="12" md="6">
                  <ViewData
                    label="Razón social"
                    :value="data.rs_service_insured_bill.legal_name"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="RFC"
                    :value="data.rs_service_insured_bill.code_tax"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Código postal"
                    :value="data.rs_service_insured_bill.legal_zip"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="6">
                  <ViewData
                    label="Régimen"
                    :value="
                      data.rs_service_insured_bill.fiscal_regime.fiscal_regime
                    "
                  />
                </v-col>
                <v-col cols="12" xs="12" md="6">
                  <ViewData
                    label="Uso CFDI"
                    :value="data.rs_service_insured_bill.fiscal_use.fiscal_use"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" xs="12" md="6">
                  <ViewData
                    label="Forma de pago"
                    :value="
                      data.rs_service_insured_bill.fiscal_method_payment
                        .fiscal_method_payment
                    "
                  />
                </v-col>
                <v-col
                  v-if="
                    data.rs_service_insured_bill.fiscal_method_payment_id ==
                      4 ||
                    data.rs_service_insured_bill.fiscal_method_payment_id == 18
                  "
                  cols="12"
                  xs="12"
                  md="3"
                >
                  <ViewData
                    label="Núm. de aprobación"
                    :value="data.rs_service_insured_bill.approval_number"
                  />
                </v-col>
                <v-col
                  v-if="
                    data.rs_service_insured_bill.fiscal_method_payment_id ==
                      4 ||
                    data.rs_service_insured_bill.fiscal_method_payment_id == 18
                  "
                  cols="12"
                  xs="12"
                  md="3"
                >
                  <ViewData
                    label="Tarjeta (4 últ. dig.)"
                    :value="data.rs_service_insured_bill.card"
                  />
                </v-col>
                <v-col cols="12">
                  <ViewData
                    label="Observación"
                    :value="data.rs_service_insured_bill.observation"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span class="text-caption" v-text="`DOC. ID | ${data.folio}`" />
              </v-toolbar-title>
            </v-toolbar>
            <v-spacer />
            <v-card-text>
              <v-row dense>
                <v-col cols="12" v-if="!data.to_stamp">
                  <v-alert type="warning" dense>
                    <div
                      class="text-center"
                      v-text="'La documentación necesita revisión'"
                    />
                    <div
                      v-if="!data.valid_receiver_code_taxs"
                      class="text-center"
                      v-text="
                        '*El RFC del receptor de una o varias facturas no es correcta'
                      "
                    />
                    <div
                      v-if="!data.valid_receiver_names"
                      class="text-center"
                      v-text="
                        '*La razón social del receptor de una o varias facturas no es correcta'
                      "
                    />
                    <div
                      v-if="!data.valid_amounts"
                      class="text-center"
                      v-text="
                        '*El monto del formato o el ingresado no concuerda con el total de una o varias facturas'
                      "
                    />
                  </v-alert>
                </v-col>
                <v-col cols="12" v-if="data.rs_service.provider_id">
                  <span
                    class="text-caption font-weight-bold mr-1"
                    v-text="'Identificación'"
                  />
                  <v-icon
                    right
                    small
                    :color="data.url_credential ? 'success' : 'warning'"
                  >
                    {{ data.url_credential ? "mdi-check" : "mdi-alert" }}
                  </v-icon>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <span
                    class="text-caption font-weight-bold mr-1"
                    v-text="'Total factura'"
                  />
                  <span v-text="numberFormat(data.xmls_amount)" />
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <span
                    class="text-caption font-weight-bold mr-1"
                    v-text="'Total IVA'"
                  />
                  <span v-text="numberFormat(data.xmls_tax_amount)" />
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <div v-if="login.role_id">
                    <span
                      class="text-caption font-weight-bold mr-1"
                      v-text="'Total Nota de Crédito'"
                    />
                    <span v-text="numberFormat(data.notes_amount)" />
                  </div>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <span
                    class="text-caption font-weight-bold mr-1"
                    v-text="'Total a pagar'"
                  />
                  <span v-text="numberFormat(data.pay_amount)" />
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <span
                    class="text-caption font-weight-bold mr-1"
                    v-text="'Cobro Aseg.'"
                  />
                  <span v-text="numberFormat(data.insurance_pay_amount)" />
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <span
                    class="text-caption font-weight-bold mr-1"
                    v-text="'Remesa'"
                  />
                  <span v-text="data.consignment ? data.consignment : '-'" />
                  <v-icon
                    v-if="data.consignment && !data.consignment_verify"
                    right
                    small
                    color="warning"
                  >
                    mdi-alert
                  </v-icon>
                </v-col>
                <v-col
                  cols="12"
                  v-for="(document, i) in data.documents"
                  :key="i"
                >
                  <v-row dense>
                    <v-col cols="12" class="grey darken-3 white--text">
                      <h4
                        v-text="`Factura (${i + 1}) | ${document.xml_folio}`"
                        class="pl-1"
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="2">
                      <span
                        class="text-caption font-weight-bold mr-1"
                        v-text="'Factura PDF'"
                      />
                      <v-icon
                        right
                        small
                        :color="document.url_bill ? 'success' : 'warning'"
                      >
                        {{ document.url_bill ? "mdi-check" : "mdi-alert" }}
                      </v-icon>

                      <v-tooltip bottom v-if="document.url_bill">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            icon
                            small
                            v-on="on"
                            :href="
                              url_documents +
                              '/rs_service_bill_documents/' +
                              document.url_bill
                            "
                            target="_blank"
                          >
                            <v-icon small>mdi-eye</v-icon>
                          </v-btn>
                        </template>
                        <span v-text="'Ver'" />
                      </v-tooltip>
                    </v-col>
                    <v-col cols="12" sm="12" md="2">
                      <span
                        class="text-caption font-weight-bold mr-1"
                        v-text="'Factura XML'"
                      />
                      <v-icon
                        right
                        small
                        :color="document.url_bill_xml ? 'success' : 'warning'"
                      >
                        {{ document.url_bill_xml ? "mdi-check" : "mdi-alert" }}
                      </v-icon>
                      <v-tooltip bottom v-if="document.url_bill_xml">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            icon
                            small
                            v-on="on"
                            :href="
                              url_documents +
                              '/rs_service_bill_documents/' +
                              document.url_bill_xml
                            "
                            target="_blank"
                          >
                            <v-icon small>mdi-eye</v-icon>
                          </v-btn>
                        </template>
                        <span v-text="'Ver'" />
                      </v-tooltip>
                    </v-col>
                    <v-col cols="12" sm="12" md="2">
                      <span
                        class="text-caption font-weight-bold mr-1"
                        v-text="'RFC y razon social'"
                      />
                      <v-icon
                        right
                        small
                        :color="
                          document.valid_receiver_code_tax &&
                          document.valid_receiver_name
                            ? 'success'
                            : 'warning'
                        "
                      >
                        {{
                          document.valid_receiver_code_tax &&
                          document.valid_receiver_name
                            ? "mdi-check"
                            : "mdi-alert"
                        }}
                      </v-icon>
                    </v-col>
                    <v-col cols="12" sm="12" md="3" />
                    <!-- <v-col cols="12" sm="12" md="3">
                      <div v-if="data.rs_service.provider_id">
                        <span
                          class="text-caption font-weight-bold mr-1"
                          v-text="'Formato edo. cuenta monto con IVA'"
                        />
                        <span
                          :class="
                            document.status_account_valid === null ||
                            (document.status_account_valid &&
                              document.status_account_amount_valid)
                              ? ''
                              : 'orange--text text--darken-3'
                          "
                          v-text="numberFormat(document.status_account_amount)"
                        />
                      </div>
                    </v-col> -->
                    <v-col cols="12" sm="12" md="3">
                      <span
                        class="text-caption font-weight-bold mr-1"
                        v-text="'Monto total con IVA'"
                      />
                      <span v-text="numberFormat(document.amount_typed)" />
                    </v-col>
                    <v-col cols="12" sm="12" md="2">
                      <span
                        class="text-caption font-weight-bold mr-1"
                        v-text="'Carta autorización'"
                      />
                      <v-icon
                        right
                        small
                        :color="
                          document.url_letter_auth ? 'success' : 'warning'
                        "
                      >
                        {{
                          document.url_letter_auth ? "mdi-check" : "mdi-alert"
                        }}
                      </v-icon>

                      <v-tooltip bottom v-if="document.url_letter_auth">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            icon
                            small
                            v-on="on"
                            :href="
                              url_documents +
                              '/rs_service_bill_documents/' +
                              document.url_letter_auth
                            "
                            target="_blank"
                          >
                            <v-icon small>mdi-eye</v-icon>
                          </v-btn>
                        </template>
                        <span v-text="'Ver'" />
                      </v-tooltip>
                    </v-col>
                    <v-col cols="12" sm="12" md="2">
                      <div v-if="data.rs_service.provider_id">
                        <span
                          class="text-caption font-weight-bold mr-1"
                          v-text="'Otros'"
                        />
                        <v-icon right small>
                          {{
                            data["document_other_" + i]
                              ? "mdi-check"
                              : "mdi-alert"
                          }}
                        </v-icon>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="12" md="5">
                      <div v-if="data.rs_service.provider_id">
                        <span
                          class="text-caption font-weight-bold mr-1"
                          v-text="'Descripción'"
                        />
                        <span
                          class="text-caption"
                          v-text="document.other_description"
                        />
                      </div>
                    </v-col>
                    <v-col cols="12" sm="12" md="3">
                      <span
                        class="text-caption font-weight-bold mr-1"
                        v-text="'Monto XML'"
                      />
                      <span v-text="numberFormat(document.xml_amount)" />
                    </v-col>
                    <v-col
                      cols="12"
                      v-for="(note, j) in document.notes"
                      :key="j"
                    >
                      <v-row dense>
                        <v-col cols="12">
                          <v-divider />
                        </v-col>
                        <v-col cols="12" sm="12" md="3">
                          <span
                            class="text-caption font-weight-bold mr-1"
                            v-text="
                              `N. crédito PDF(${i + 1}.${j + 1}) ${
                                note.xml_folio ? `| ${note.xml_folio}` : ''
                              }`
                            "
                          />

                          <v-icon
                            right
                            small
                            :color="note.url ? 'success' : 'warning'"
                          >
                            {{ note.url ? "mdi-check" : "mdi-alert" }}
                          </v-icon>
                          <v-tooltip bottom v-if="note.url">
                            <template v-slot:activator="{ on }">
                              <v-btn
                                icon
                                small
                                v-on="on"
                                :href="
                                  url_documents +
                                  '/rs_service_bill_notes/' +
                                  note.url
                                "
                                target="_blank"
                              >
                                <v-icon small>mdi-eye</v-icon>
                              </v-btn>
                            </template>
                            <span v-text="'Ver'" />
                          </v-tooltip>
                        </v-col>
                        <v-col cols="12" sm="12" md="3">
                          <span
                            class="text-caption font-weight-bold mr-1"
                            v-text="'N. crédito XML'"
                          />
                          <v-icon
                            right
                            small
                            :color="note.url_xml ? 'success' : 'default'"
                          >
                            {{ note.url_xml ? "mdi-check" : "mdi-alert" }}
                          </v-icon>
                          <v-tooltip bottom v-if="note.url_xml">
                            <template v-slot:activator="{ on }">
                              <v-btn
                                icon
                                small
                                v-on="on"
                                :href="
                                  url_documents +
                                  '/rs_service_bill_notes/' +
                                  note.url_xml
                                "
                                target="_blank"
                              >
                                <v-icon small>mdi-eye</v-icon>
                              </v-btn>
                            </template>
                            <span v-text="'Ver'" />
                          </v-tooltip>
                        </v-col>
                        <v-col cols="12" sm="12" md="3">
                          <span
                            class="text-caption font-weight-bold mr-1"
                            v-text="'Monto XML'"
                          />
                          <span
                            v-if="note.xml_amount"
                            v-text="numberFormat(note.xml_amount)"
                          />
                          <v-icon v-else right small color="default">
                            mdi-alert
                          </v-icon>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" v-if="!data.stamp_id && !data.consignment_verify">
          <v-btn color="error" small @click.prevent="rsServiceBillDesactivate">
            Eliminar documentación
            <v-icon small> mdi-file-remove </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <v-dialog
      v-model="report_12_dialog"
      scrollable
      persistent
      max-width="600px"
    >
      <v-card tile :disabled="loading" :loading="loading">
        <v-toolbar dark dense>
          <v-toolbar-title> Fechas línea 12</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="report_12_dialog = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="data && report_12_data">
          <v-row>
            <v-col cols="12">
              <v-spacer />
            </v-col>
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'FECHAS'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-form
                    v-on:submit.prevent
                    ref="form_report_12_data"
                    lazy-validation
                  >
                    <v-row dense>
                      <v-col cols="12" xs="12" md="6">
                        <DatePicker
                          label="Ingreso*"
                          :model.sync="report_12_data.admission_date_report"
                          clean
                          all_date
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="6">
                        <DatePicker
                          label="Alta*"
                          :model.sync="report_12_data.discharged_report"
                          clean
                          all_date
                        />
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-btn
                block
                dark
                small
                color="blue-grey darken-3"
                @click.prevent="report12Save"
              >
                Guardar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="pay_day_dialog" scrollable persistent max-width="600px">
      <v-card tile :disabled="loading" :loading="loading">
        <v-toolbar dark dense>
          <v-toolbar-title> Día de recepción y pago</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="pay_day_dialog = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="data && pay_day_data">
          <v-row>
            <v-col cols="12">
              <v-spacer />
            </v-col>
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'INFORMACIÓN'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" class="text-center">
                      <p>
                        Esta función recalculara el dia de recepción y pago con
                        los siguientes datos:
                      </p>
                      <p><b>Fecha registro: </b> {{ data.created_at }}</p>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" xs="12" md="6">
              <v-btn
                block
                dark
                small
                color="cyan darken-3"
                @click.prevent="payDaySave(true)"
              >
                Fecha registro
              </v-btn>
            </v-col>
            <v-col cols="12" xs="12" md="6">
              <v-btn
                block
                dark
                small
                color="grey"
                @click.prevent="payDaySave(false)"
              >
                Fecha actual
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="amounts_dialog" scrollable persistent max-width="900px">
      <v-card tile :disabled="loading" :loading="loading">
        <v-toolbar dark dense>
          <v-toolbar-title> Montos</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="amounts_dialog = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="data && amounts_data">
          <v-row>
            <v-col cols="12">
              <v-spacer />
            </v-col>
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'DETALLE'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-form
                    v-on:submit.prevent
                    ref="form_amounts_data"
                    lazy-validation
                  >
                    <v-row dense>
                      <v-col cols="12" xs="12" md="4">
                        <ViewData
                          label="Total a pagar"
                          :value="numberFormat(data.pay_amount)"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="4">
                        <ViewData
                          label="Cobro Aseguradora"
                          :value="numberFormat(data.insurance_pay_amount)"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="4">
                        <ViewData
                          label="Monto Asegurado"
                          :value="numberFormat(data.rs_service.assured_amount)"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="4">
                        <v-text-field
                          label="Total a pagar*"
                          v-model="amounts_data.pay_amount"
                          :rules="rules.required"
                          type="number"
                          dense
                          min="0"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="4">
                        <v-text-field
                          label="Cobro Aseguradora*"
                          v-model="amounts_data.insurance_pay_amount"
                          :rules="rules.required"
                          type="number"
                          dense
                          min="0"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="4">
                        <v-text-field
                          label="Monto Asegurado*"
                          v-model="amounts_data.assured_amount"
                          :rules="rules.required"
                          type="number"
                          dense
                          min="0"
                        />
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-btn
                block
                dark
                small
                color="teal darken-3"
                @click.prevent="amountsSave"
              >
                Guardar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="observation_dialog"
      scrollable
      persistent
      max-width="900px"
    >
      <v-card tile :disabled="loading" :loading="loading">
        <v-toolbar dark dense>
          <v-toolbar-title> Observación </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="observation_dialog = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="data && observation_data">
          <v-row>
            <v-col cols="12">
              <v-spacer />
            </v-col>
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'DETALLE'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-form
                    v-on:submit.prevent
                    ref="form_observation_data"
                    lazy-validation
                  >
                    <v-row dense>
                      <v-col cols="12">
                        <v-textarea
                          v-model="observation_data.observation"
                          label=""
                          dense
                        />
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-btn
                block
                dark
                small
                color="error"
                @click.prevent="observationSave"
              >
                Guardar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="observation_admin_dialog"
      scrollable
      persistent
      max-width="900px"
    >
      <v-card tile :disabled="loading" :loading="loading">
        <v-toolbar dark dense>
          <v-toolbar-title> Observación administrativa</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="observation_admin_dialog = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="data && observation_admin_data">
          <v-row>
            <v-col cols="12">
              <v-spacer />
            </v-col>
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'DETALLE'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-form
                    v-on:submit.prevent
                    ref="form_observation_admin_data"
                    lazy-validation
                  >
                    <v-row dense>
                      <v-col cols="12">
                        <v-textarea
                          v-model="observation_admin_data.observation_admin"
                          label=""
                          dense
                        />
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-btn
                block
                dark
                small
                color="info"
                @click.prevent="observationAdminSave"
              >
                Guardar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="insurance_reception_dialog"
      scrollable
      persistent
      max-width="1200px"
    >
      <v-card
        tile
        :disabled="insurance_reception_dialog_loading"
        :loading="insurance_reception_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title> Documentación aseguradora </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="insurance_reception_dialog = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="data && insurance_reception_data">
          <v-row>
            <v-col cols="12">
              <v-spacer />
            </v-col>
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'REGISTRO'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-form
                    v-on:submit.prevent
                    ref="form_insurance_reception_data"
                    lazy-validation
                  >
                    <v-row dense>
                      <v-col cols="12" xs="12" md="3">
                        <DatePicker
                          label="Fecha"
                          :model.sync="
                            insurance_reception_data.insurance_reception_date
                          "
                          clean
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="3">
                        <v-text-field
                          v-model="
                            insurance_reception_data.insurance_reception_time
                          "
                          label="Hora"
                          type="time"
                          dense
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="3">
                        <DatePicker
                          label="Promesa de pago"
                          :model.sync="
                            insurance_reception_data.insurance_pay_date
                          "
                          clean
                          all_date
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="3">
                        <v-text-field
                          v-model="insurance_reception_data.insurance_procedure"
                          label="Núm. trámite"
                          type="text"
                          dense
                          :rules="rules.required"
                          :disabled="data.consignment ? true : false"
                        />
                      </v-col>
                      <!-- <v-col cols="12" xs="12" md="3">
                        <v-text-field
                          v-model="
                            insurance_reception_data.insurance_bill_folio
                          "
                          label="Factura folio"
                          type="text"
                          dense
                          maxlength="30"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="3">
                        <v-text-field
                          v-model="
                            insurance_reception_data.insurance_restitution
                          "
                          label="Factura folio"
                          type="text"
                          dense
                          maxlength="30"
                        />
                      </v-col> -->
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-btn
                block
                color="success"
                small
                @click.prevent="insuranceReceptionSave"
              >
                Guardar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="insurance_dialog"
      scrollable
      persistent
      max-width="1200px"
    >
      <v-card
        tile
        :disabled="insurance_dialog_loading"
        :loading="insurance_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title> Documentación aseguradora </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="insurance_dialog = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="insurance_data">
          <v-row>
            <v-col cols="12">
              <v-spacer />
            </v-col>
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title class="py-2">
                  <v-row dense>
                    <v-col cols="12" xs="12" md="8">
                      <h2 class="text-caption" v-text="'REGISTRO'" />
                    </v-col>
                    <v-col cols="12" xs="12" md="4" class="text-right">
                      <!-- <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            fab
                            dark
                            x-small
                            class="ml-1"
                            color="info"
                            @click="insuranceReceptionDialog"
                          >
                            <v-icon> mdi-pencil </v-icon>
                          </v-btn>
                        </template>
                        <span v-text="'Editar'" />
                      </v-tooltip> -->
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Fecha"
                        :value="insurance_data.insurance_reception_date"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Hora"
                        :value="insurance_data.insurance_reception_time"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Promesa de pago"
                        :value="insurance_data.insurance_pay_date"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Núm. trámite"
                        :value="insurance_data.insurance_procedure"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Restitución"
                        :value="
                          insurance_data.restitution
                            ? insurance_data.restitution.folio +
                              ' | ' +
                              insurance_data.restitution.created_at
                            : 'NO REG.'
                        "
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title class="py-2">
                  <v-row dense>
                    <v-col cols="12" xs="12" md="8">
                      <h2 class="text-caption" v-text="'PAGOS'" />
                    </v-col>
                    <v-col cols="12" xs="12" md="4" class="text-right">
                      <v-tooltip
                        v-if="
                          insurance_data.insurance_reception_date &&
                          !insurance_data.insurance_paid_date &&
                          !insurance_data.restitution
                        "
                        left
                      >
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            fab
                            dark
                            x-small
                            class="ml-1"
                            color="success"
                            @click="rsBillInsurancePaymentDialog"
                          >
                            <v-icon> mdi-plus </v-icon>
                          </v-btn>
                        </template>
                        <span v-text="'Agregar pago aseguradora'" />
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-simple-table
                        v-if="
                          insurance_data.rs_bill_insurance_payments.length > 0
                        "
                        dense
                      >
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left" width="50px">#</th>
                              <th class="text-left">ID</th>
                              <th class="text-left">Payment ID</th>
                              <th class="text-left">Fecha</th>
                              <th class="text-left">Monto</th>
                              <th class="text-left" width="150px" />
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(
                                rs_bill_insurance_payment, i
                              ) in insurance_data.rs_bill_insurance_payments"
                              :key="i"
                            >
                              <td>
                                <b v-text="parseInt(i) + 1" />
                              </td>
                              <td v-text="rs_bill_insurance_payment.folio" />
                              <td
                                v-text="rs_bill_insurance_payment.payment_id"
                              />
                              <td
                                v-text="rs_bill_insurance_payment.paid_date"
                              />
                              <td
                                v-text="
                                  numberFormat(rs_bill_insurance_payment.amount)
                                "
                              />
                              <td class="text-right">
                                <v-tooltip
                                  v-if="
                                    login.role_id == 1 &&
                                    !rs_bill_insurance_payment.stamp_receipt_id
                                  "
                                  left
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      v-on="on"
                                      icon
                                      x-small
                                      class="ml-1"
                                      color="error"
                                      @click="
                                        rsBillInsurancePaymentDelete(
                                          rs_bill_insurance_payment
                                        )
                                      "
                                    >
                                      <v-icon small> mdi-close </v-icon>
                                    </v-btn>
                                  </template>
                                  <span v-text="'Eliminar'" />
                                </v-tooltip>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      v-on="on"
                                      icon
                                      x-small
                                      class="ml-1"
                                      color="cyan darken-1"
                                      dark
                                      @click.prevent="
                                        downloadFile(
                                          rs_bill_insurance_payment.stamp_receipt_id,
                                          'pdf'
                                        )
                                      "
                                    >
                                      <v-icon small> mdi-file-download </v-icon>
                                    </v-btn>
                                  </template>
                                  <span v-text="'C. pago PDF'" />
                                </v-tooltip>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      v-on="on"
                                      icon
                                      x-small
                                      class="ml-1"
                                      color="teal darken-1"
                                      dark
                                      @click.prevent="
                                        downloadFile(
                                          rs_bill_insurance_payment.stamp_receipt_id,
                                          'xml'
                                        )
                                      "
                                    >
                                      <v-icon small> mdi-xml </v-icon>
                                    </v-btn>
                                  </template>
                                  <span v-text="'C. pago XML'" />
                                </v-tooltip>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                      <div
                        v-else
                        class="text-center"
                        v-text="'Sin registros'"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="rs_bill_insurance_payment_dlg"
      scrollable
      persistent
      max-width="300px"
    >
      <v-card
        tile
        :disabled="rs_bill_insurance_payment_dlg_ldg"
        :loading="rs_bill_insurance_payment_dlg_ldg"
      >
        <v-toolbar dark dense>
          <v-toolbar-title> Pago aseguradora </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="rs_bill_insurance_payment_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-spacer />
            </v-col>
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'INFORMACIÓN'" />
                </v-card-title>
                <v-divider />
                <v-card-text v-if="rs_bill_insurance_payment_data">
                  <v-form
                    v-on:submit.prevent
                    ref="form_rs_bill_insurance_payment_data"
                    lazy-validation
                  >
                    <v-row dense>
                      <v-col cols="12">
                        <DatePicker
                          label="F. Pago"
                          :model.sync="rs_bill_insurance_payment_data.paid_date"
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          label="Monto*"
                          v-model="rs_bill_insurance_payment_data.amount"
                          type="number"
                          dense
                          min="0"
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          label="Payment ID*"
                          v-model="rs_bill_insurance_payment_data.payment_id"
                          type="text"
                          dense
                          :rules="rules.required"
                          maxlength="20"
                          counter
                        />
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-btn
                block
                dark
                small
                color="success"
                @click.prevent="rsBillInsurancePaymentStore"
              >
                <v-icon small left> mdi-plus </v-icon>
                Agregar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="taxed_dlg" scrollable persistent max-width="300px">
      <v-card tile :disabled="taxed_dlg_ldg" :loading="taxed_dlg_ldg">
        <v-toolbar dark dense>
          <v-toolbar-title> Gravado y Exento </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="taxed_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="taxed" class="pt-5">
          <v-form v-on:submit.prevent ref="taxed_form" lazy-validation>
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  label="Monto gravado"
                  v-model="taxed.taxed_amount"
                  :rules="rules.required"
                  type="number"
                  dense
                  min="0"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Monto excento"
                  v-model="taxed.zero_rate_amount"
                  :rules="rules.required"
                  type="number"
                  dense
                  min="0"
                />
              </v-col>
              <v-col cols="12">
                <v-btn
                  block
                  dark
                  small
                  color="light-green darken-3"
                  @click.prevent="taxedSave"
                >
                  <v-icon small left> mdi-send </v-icon>
                  Continuar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  URL_DOCUMENTS,
  URL_PDF,
  msgConfirm,
  msgAlert,
  dateTimeNow,
  rules,
  base64ToArrayBuffer,
  dateTimeToFile,
} from "../../control";
import ViewData from "../../components/ViewData.vue";
import DatePicker from "../../components/DatePicker.vue";
import FaqDlg from "../../components/FaqDlg.vue";
import RhaWithRsService from "../../components/RhaWithRsService.vue";

export default {
  components: {
    ViewData,
    DatePicker,
    FaqDlg,
    RhaWithRsService,
  },
  data() {
    return {
      id: this.$attrs.id,
      login: this.$store.getters.getLogin,
      loading: false,
      data: null,
      url_documents: URL_DOCUMENTS,
      url_pdf: URL_PDF,
      rules: rules(),
      report_12_data: null,
      report_12_dialog: false,
      pay_day_data: null,
      pay_day_dialog: false,
      amounts_data: null,
      amounts_dialog: false,
      observation_data: null,
      observation_dialog: false,
      observation_admin_data: null,
      observation_admin_dialog: false,
      insurance_reception_data: null,
      insurance_reception_dialog: false,
      insurance_reception_dialog_loading: false,
      insurance_data: false,
      insurance_dialog: false,
      insurance_dialog_loading: false,
      rs_bill_insurance_payment_data: null,
      rs_bill_insurance_payment_dlg: false,
      rs_bill_insurance_payment_dlg_ldg: false,
      rha: null,
      taxed: null,
      taxed_dlg: false,
      taxed_dlg_ldg: true,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    getData() {
      this.loading = true;
      this.data = null;

      Axios.get(
        `${URL_API}/rss/services/bills/${this.id}`,
        headersToken(this.login.token)
      ).then((resp) => {
        this.data = resp.data.data;

        Axios.get(
          `${URL_API}/rss/services/${this.data.rs_service_id}/rha`,
          headersToken(this.login.token)
        ).then((resp) => {
          this.rha = resp.data.data;

          if (this.data.rs_service_insured_bill_id) {
            Axios.get(
              `${URL_API}/rss/services/insured/bills/${this.data.rs_service_insured_bill_id}`,
              headersToken(this.login.token)
            ).then((resp) => {
              this.data.rs_service_insured_bill = resp.data.data;
              this.loading = false;
            });
          } else {
            this.loading = false;
          }
        });
      });
    },
    consigmentVerify() {
      this.$swal
        .fire(msgConfirm(`¿Confirma la validación de la documentación?`))
        .then((resp) => {
          if (resp.isConfirmed) {
            this.loading = true;

            Axios.post(
              URL_API + "/rss/services/bills/consignment/verify",
              {
                id: this.id,
              },
              headersToken(this.login.token)
            ).then((resp) => {
              this.$swal.fire(
                msgAlert(
                  resp.data.success ? "success" : "error",
                  resp.data.message
                )
              );

              if (resp.data.success) {
                this.getData();
              } else {
                console.log(resp.data.message);
                this.loading = false;
              }
            });
          }
        });
    },
    report12Dialog() {
      this.report_12_dialog = false;

      this.report_12_data = {
        id: this.data.rs_service_id,
        admission_date_report: this.data.rs_service.admission_date_report,
        discharged_report: this.data.rs_service.discharged_report,
      };

      this.report_12_dialog = true;
    },
    report12Save() {
      if (this.$refs.form_report_12_data.validate()) {
        this.$swal
          .fire(msgConfirm(`¿Confirma guardar la información?`))
          .then((resp) => {
            if (resp.isConfirmed) {
              this.loading = true;

              Axios.post(
                URL_API + `/rss/services/report/dates`,
                this.report_12_data,
                headersToken(this.login.token)
              ).then((resp) => {
                this.$swal.fire(
                  msgAlert(
                    resp.data.success ? "success" : "error",
                    resp.data.message
                  )
                );

                if (resp.data.success) {
                  this.getData();
                  this.report_12_dialog = false;
                } else {
                  console.log(resp.data.message);
                }

                this.loading = false;
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    payDayDialog() {
      this.pay_day_dialog = false;

      this.pay_day_data = {
        id: this.data.id,
        get_created_at: null,
      };

      this.pay_day_dialog = true;
    },
    payDaySave(get_created_at) {
      this.$swal.fire(msgConfirm(`¿Confirma esta acción?`)).then((resp) => {
        if (resp.isConfirmed) {
          this.loading = true;
          this.pay_day_data.get_created_at = get_created_at;

          Axios.post(
            URL_API + `/rss/services/bills/recalculate/pay/day`,
            this.pay_day_data,
            headersToken(this.login.token)
          ).then((resp) => {
            this.$swal.fire(
              msgAlert(
                resp.data.success ? "success" : "error",
                resp.data.message
              )
            );

            if (resp.data.success) {
              this.getData();
              this.pay_day_dialog = false;
            } else {
              console.log(resp.data.message);
            }

            this.loading = false;
          });
        }
      });
    },
    amountsDialog() {
      this.amounts_dialog = false;

      this.amounts_data = {
        id: this.data.id,
        pay_amount: this.data.pay_amount,
        insurance_pay_amount: this.data.insurance_pay_amount,
        assured_amount: this.data.rs_service.assured_amount,
      };

      this.amounts_dialog = true;
    },
    amountsSave() {
      if (this.$refs.form_amounts_data.validate()) {
        this.$swal
          .fire(msgConfirm(`¿Confirma guardar la información?`))
          .then((resp) => {
            if (resp.isConfirmed) {
              this.loading = true;

              Axios.post(
                URL_API + `/rss/services/bills/change/amounts`,
                this.amounts_data,
                headersToken(this.login.token)
              ).then((resp) => {
                this.$swal.fire(
                  msgAlert(
                    resp.data.success ? "success" : "error",
                    resp.data.message
                  )
                );

                if (resp.data.success) {
                  this.getData();
                  this.amounts_dialog = false;
                } else {
                  console.log(resp.data.message);
                  this.loading = false;
                }
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    observationDialog() {
      this.observation_dialog = false;

      this.observation_data = {
        id: this.data.id,
        observation: this.data.observation,
      };

      this.observation_dialog = true;
    },
    observationSave() {
      if (this.$refs.form_observation_data.validate()) {
        this.$swal
          .fire(msgConfirm(`¿Confirma guardar la información?`))
          .then((resp) => {
            if (resp.isConfirmed) {
              this.loading = true;

              Axios.post(
                URL_API + `/rss/services/bills/observation`,
                this.observation_data,
                headersToken(this.login.token)
              ).then((resp) => {
                this.$swal.fire(
                  msgAlert(
                    resp.data.success ? "success" : "error",
                    resp.data.message
                  )
                );

                if (resp.data.success) {
                  this.getData();
                  this.observation_dialog = false;
                } else {
                  console.log(resp.data.message);
                }

                this.loading = false;
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    observationAdminDialog() {
      this.observation_admin_dialog = false;

      this.observation_admin_data = {
        id: this.data.id,
        observation_admin: this.data.observation_admin,
      };

      this.observation_admin_dialog = true;
    },
    observationAdminSave() {
      if (this.$refs.form_observation_admin_data.validate()) {
        this.$swal
          .fire(msgConfirm(`¿Confirma guardar la información?`))
          .then((resp) => {
            if (resp.isConfirmed) {
              this.loading = true;

              Axios.post(
                URL_API + `/rss/services/bills/observation/admin`,
                this.observation_admin_data,
                headersToken(this.login.token)
              ).then((resp) => {
                this.$swal.fire(
                  msgAlert(
                    resp.data.success ? "success" : "error",
                    resp.data.message
                  )
                );

                if (resp.data.success) {
                  this.getData();
                  this.observation_admin_dialog = false;
                } else {
                  console.log(resp.data.message);
                }

                this.loading = false;
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    rsServiceBillDesactivate() {
      this.$swal
        .fire(msgConfirm(`¿Confirma eliminar la documentación?`))
        .then((resp) => {
          if (resp.isConfirmed) {
            this.loading = true;
            Axios.delete(
              URL_API + "/rss/services/bills/" + this.data.id,
              headersToken(this.login.token)
            ).then((resp) => {
              this.$swal.fire(
                msgAlert(
                  resp.data.success ? "success" : "error",
                  resp.data.message
                )
              );

              if (resp.data.success) {
                this.$router.push({
                  name: "facturacion_servicios",
                });
                this.loading = false;
              }
            });
          }
        });
    },
    downloadFile(stamped_id, extension) {
      this.service_payment_info_dialog_loading = true;

      Axios.get(
        URL_API + `/bills/stamped/${stamped_id}/files/${extension}/2`,
        headersToken(this.login.token)
      ).then((resp) => {
        this.$swal.fire(
          msgAlert(resp.data.success ? "success" : "error", resp.data.message)
        );

        if (resp.data.success) {
          const linkSource = `data:application/${extension};base64,${resp.data.data}`;
          const downloadLink = document.createElement("a");
          const fileName = `sm_bill_${stamped_id}.${extension}`;

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        } else {
          console.log(resp.data.message);
        }

        this.service_payment_info_dialog_loading = false;
      });
    },
    numberFormat(v) {
      return Number(parseFloat(v).toFixed(2)).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    insuranceReceptionDialog() {
      this.insurance_dialog = false;
      const dateTime = dateTimeNow();

      this.insurance_reception_data = {
        id: this.id,
        insurance_reception_date: dateTime.substring(0, 10),
        insurance_reception_time: dateTime.substring(11, 16),
        insurance_pay_date: dateTime.substring(0, 10),
        insurance_procedure: this.data.consignment ? this.data.consignment : "",
        insurance_bill_folio: this.data.insurance_bill_folio
          ? this.data.insurance_bill_folio
          : "",
        insurance_restitution: this.data.insurance_restitution
          ? this.data.insurance_restitution
          : "",
      };

      this.insurance_reception_dialog_loading = false;
      this.insurance_reception_dialog = true;
    },
    insuranceReceptionSave() {
      if (this.$refs.form_insurance_reception_data.validate()) {
        this.$swal
          .fire(msgConfirm(`¿Confirma guardar la información?`))
          .then((resp) => {
            if (resp.isConfirmed) {
              this.insurance_reception_dialog_loading = true;

              Axios.post(
                URL_API + `/rss/services/bills/insurance/reception`,
                this.insurance_reception_data,
                headersToken(this.login.token)
              ).then((resp) => {
                this.$swal.fire(
                  msgAlert(
                    resp.data.success ? "success" : "error",
                    resp.data.message
                  )
                );

                if (resp.data.success) {
                  this.getData();
                  this.insurance_reception_dialog = false;
                } else {
                  console.log(resp.data.message);
                }

                this.insurance_reception_dialog_loading = false;
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    insuranceDlg() {
      this.insurance_data = null;
      this.insurance_dialog_loading = true;
      this.insurance_dialog = true;

      Axios.get(
        `${URL_API}/rss/services/bills/${this.id}/insurance`,
        headersToken(this.login.token)
      ).then((resp) => {
        this.insurance_data = resp.data.data;
        this.insurance_dialog_loading = false;
      });
    },
    rsBillInsurancePaymentDialog() {
      this.rs_bill_insurance_payment_data = {
        id: null,
        rs_service_bill_id: this.id,
        payment_id: "",
        paid_date: dateTimeNow().substring(0, 10),
        amount: "",
      };
      this.rs_bill_insurance_payment_dlg_ldg = false;
      this.rs_bill_insurance_payment_dlg = true;
    },
    rsBillInsurancePaymentStore() {
      if (this.$refs.form_rs_bill_insurance_payment_data.validate()) {
        this.$swal
          .fire(msgConfirm(`¿Confirma guardar la información?`))
          .then((resp) => {
            if (resp.isConfirmed) {
              this.rs_bill_insurance_payment_dlg_ldg = true;

              Axios.post(
                URL_API + `/rss/services/bills/insurance/payment/store`,
                this.rs_bill_insurance_payment_data,
                headersToken(this.login.token)
              ).then((resp) => {
                this.$swal.fire(
                  msgAlert(
                    resp.data.success ? "success" : "error",
                    resp.data.message
                  )
                );

                if (resp.data.success) {
                  this.insuranceDlg();
                  this.rs_bill_insurance_payment_dlg = false;
                } else {
                  console.log(resp.data.message);
                }

                this.rs_bill_insurance_payment_dlg_ldg = false;
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    rsBillInsurancePaymentDelete(item) {
      this.$swal
        .fire(msgConfirm(`¿Confirma eliminar el pago con el ID ${item.folio}?`))
        .then((res) => {
          if (res.isConfirmed) {
            this.rs_bill_insurance_payment_dlg_ldg = true;

            Axios.post(
              URL_API + `/rss/services/bills/insurance/payment/delete`,
              item,
              headersToken(this.login.token)
            ).then((res) => {
              this.$swal.fire(
                msgAlert(
                  res.data.success ? "success" : "error",
                  res.data.message
                )
              );

              if (res.data.success) {
                this.insuranceDlg();
              } else {
                console.log(res.data.message);
              }

              this.rs_bill_insurance_payment_dlg_ldg = false;
            });
          }
        });
    },
    docExtDiplay(item, table_name) {
      this.loading = true;

      Axios.post(
        URL_API + "/rs/service/bills/external/display",
        {
          id: item.id,
          table_name: table_name,
          external_display: item.external_display,
        },
        headersToken(this.login.token)
      ).then((res) => {
        this.$swal.fire(
          msgAlert(res.data.success ? "success" : "error", res.data.message)
        );

        this.loading = false;
      });
    },
    taxedDlg() {
      this.taxed = {
        id: this.data.id,
        taxed_amount:
          this.data.taxed_amount == null ? "0" : this.data.taxed_amount + "",
        zero_rate_amount:
          this.data.zero_rate_amount == null
            ? "0"
            : this.data.zero_rate_amount + "",
      };
      this.taxed_dlg_ldg = false;
      this.taxed_dlg = true;
    },
    taxedSave() {
      if (this.$refs.taxed_form.validate()) {
        this.$swal
          .fire(msgConfirm(`¿Confirma guardar la información?`))
          .then((res) => {
            if (res.isConfirmed) {
              this.taxed_dlg_ldg = true;

              Axios.post(
                URL_API + "/rss/services/bills/taxed/amount",
                this.taxed,
                headersToken(this.login.token)
              ).then((res) => {
                this.$swal.fire(
                  msgAlert(
                    res.data.success ? "success" : "error",
                    res.data.message
                  )
                );

                if (res.data.success) {
                  const is_null =
                    parseFloat(this.taxed.taxed_amount) == 0 &&
                    parseFloat(this.taxed.zero_rate_amount) == 0;

                  this.data.taxed_amount = is_null
                    ? null
                    : this.taxed.taxed_amount;
                  this.data.zero_rate_amount = is_null
                    ? null
                    : this.taxed.zero_rate_amount;
                  this.taxed_dlg = false;
                } else {
                  console.log(res.data.message);
                }

                this.taxed_dlg_ldg = false;
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    downloadFile(stamp_id, ext) {
      this.insurance_dialog_loading = true;

      Axios.get(
        URL_API +
          "/bills/stamped/" +
          stamp_id +
          "/files/" +
          ext +
          "/" +
          (this.data.is_rha ? "1" : "2"),
        headersToken(this.login.token)
      ).then((res) => {
        this.$swal.fire(
          msgAlert(res.data.success ? "success" : "error", res.data.message)
        );

        if (res.data.success) {
          const blob = new Blob([base64ToArrayBuffer(res.data.data)], {
            type: "application/" + ext,
          });
          let downloadLink = document.createElement("a");
          downloadLink.setAttribute("target", "_blank");
          downloadLink.href = URL.createObjectURL(blob);
          downloadLink.download =
            "SM_bill" + stamp_id + "_" + dateTimeToFile() + "." + ext;
          downloadLink.click();
        } else {
          console.log(res.data.message);
        }

        this.insurance_dialog_loading = false;
      });
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    this.getData();
  },
};
</script>